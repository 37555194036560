export enum Views {
  RESULTS = 'RESULTS',
  SUBJECTS = 'SUBJECTS',
}

export enum State {
  VCE = 'vce',
  HSC = 'hsc',
  WACE = 'wace',
  QCE = 'qce',
  SACE = 'sace',
}

export enum LetterGrade {
  APlusPlus = 'APlusPlus',
  APlus = 'APlus',
  A = 'A',
  AMinus = 'AMinus',
  BPlus = 'BPlus',
  B = 'B',
  BMinus = 'BMinus',
  CPlus = 'CPlus',
  C = 'C',
  CMinus = 'CMinus',
  DPlus = 'DPlus',
  D = 'D',
  DMinus = 'DMinus',
  EPlus = 'EPlus',
  E = 'E',
  EMinus = 'EMinus',
}
